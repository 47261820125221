angular.module('elogbooksControllers').controller('RedirectController', function (API_URL, $http, $scope, $state, $location, apiClient, userManager, messenger, base64) {

    var notifications;
    var params;

    if ($state.params.file) {
        return $state.go('dashboard.user.files.list.details', {
            fileResource: $state.params.file
        });
    }

    if ($state.params['open-close']) {
        return $state.go('dashboard.user.reports.list.view.open-close', {
            reportResource: $state.params['open-close']
        });
    }

    if ($state.params['jobRedirectResource']) {
        return $state.go('dashboard.user.jobs.details.status', {
            resource: $state.params['jobRedirectResource']
        });
    }

    if ($state.params['quoteRedirectResource']) {
        return $state.go('dashboard.user.quote-requests.list.details.quote.workflow', {
            quoteResource: $state.params['quoteRedirectResource']
        });
    }

    if ($state.params.unsubscribe) {
        params = {
            email: $state.params.reporterEmail,
            token: $state.params.token,
            notification: $state.params.notification
        };

        return $http({
            url: API_URL + '/jobreporterfeedback/unsubscribe',
            method: "POST",
            data: params
        }).then(function (response) {
            if (response) {
                $state.go('login', {}, {reload: true}).then (function () {
                    messenger.success('REPORTER_UNSUBSCRIBE');
                });
            }
        }, function (failResponse) {
            $state.go('login', {}, {reload: true}).then (function () {
                angular.forEach(failResponse.data, function(response) {
                    messenger.error(response.message);
                });
            });
        });
    }

    if ($state.params.token) {
        params = $state.params;

        return $http({
            url: API_URL + '/jobreporterfeedback',
            method: "GET",
            params: params
        }).then(function (response) {
            if (response) {
                $state.go('reporter-feedback', { details: JSON.stringify(params) });
            }
        }, function (failResponse) {
            $state.go('login', {}, {reload: true}).then (function () {
                angular.forEach(failResponse.data, function(response) {
                    messenger.error(response.message);
                });
            });
        });
    }

    if (!$state.params.notification) {
        $state.go('dashboard.user.dashboard');
    } else if (!userManager.getUrl()) {
        $state.go('login', {
            destination: base64.encode($location.url())
        });
    } else {
        userManager.getUser().then(function (user) {
            apiClient.get(user.getLink('notifications')+'/'+$state.params.notification).then(function(response) {
                var notification = response;
                if(typeof notification === "undefined") {
                    $state.go('dashboard.user.dashboard').then(function() {
                        messenger.error('NOTIFICATION_NOT_FOUND');
                    });
                } else {
                    switch (notification.type) {
                        case 'job_proposal_requested':
                            switch ($state.params.link) {
                                case 'approve':
                                    $state.go('dashboard.user.jobs.details.status.workflow.accept-proposal', { resource: notification.getLink('resource').encode() });
                                    break;
                                case 'decline':
                                    $state.go('dashboard.user.jobs.details.status.workflow.reject-proposal', { resource: notification.getLink('resource').encode() });
                                    break;
                                default:
                                    $state.go('dashboard.user.jobs.details.status', { resource: notification.getLink('resource').encode() });
                            }
                            break;
                        case 'job_extension_requested':
                            switch ($state.params.link) {
                                case 'approve':
                                    $state.go('dashboard.user.jobs.details.status.workflow.accept-extension', { resource: notification.getLink('resource').encode() });
                                    break;
                                case 'decline':
                                    $state.go('dashboard.user.jobs.details.status.workflow.reject-extension', { resource: notification.getLink('resource').encode() });
                                    break;
                                default:
                                    $state.go('dashboard.user.jobs.details.status', { resource: notification.getLink('resource').encode() });
                            }
                            break;
                        case 'job_approval_required':
                            $state.go('dashboard.user.jobs.approval');
                            break;
                        case 'job_assignment_rejected':
                            switch ($state.params.link) {
                                case 'cancel':
                                    $state.go('dashboard.user.jobs.details.status.workflow.cancel', { resource: notification.getLink('resource').encode() });
                                    break;
                                case 'reassign':
                                    $state.go('dashboard.user.jobs.details.status.workflow.reassign-assignment', { resource: notification.getLink('resource').encode() });
                                    break;
                                case 'insist':
                                    $state.go('dashboard.user.jobs.details.status.workflow.insist-assignment', { resource: notification.getLink('resource').encode() });
                                    break;
                                default:
                                    $state.go('dashboard.user.jobs.details.status', { resource: notification.getLink('resource').encode() });
                            }
                            break;
                        case 'job_assignment_request':
                        case 'job_assignment_insisted':
                        case 'job_assignment_reassigned':
                        case 'job_escalation_added':
                        case 'job_escalation_removed':
                            switch ($state.params.link) {
                                case 'acknowledge':
                                    $state.go('dashboard.user.jobs.details.status.workflow.accept-assignment', { resource: notification.getLink('resource').encode() });
                                    break;
                                case 'sla':
                                    $state.go('dashboard.user.jobs.details.status.workflow.request-proposal', { resource: notification.getLink('resource').encode() });
                                    break;
                                case 'decline':
                                    $state.go('dashboard.user.jobs.details.status.workflow.reject-assignment', { resource: notification.getLink('resource').encode() });
                                    break;
                                default:
                                    $state.go('dashboard.user.jobs.details.status', { resource: notification.getLink('resource').encode() });
                            }
                            break;
                        case 'job_operation_completed':
                        case 'job_operation_completed_with_remedials':
                        case 'job_operation_reopened':
                        case 'job_values_added':
                            switch ($state.params.link) {
                                case 'reopen':
                                    $state.go('dashboard.user.jobs.details.status.workflow.reopen', { resource: notification.getLink('resource').encode() });
                                    break;
                                default:
                                    $state.go('dashboard.user.jobs.details.status', { resource: notification.getLink('resource').encode() });
                            }
                            break;
                        case 'job_approval_request':
                            switch ($state.params.link) {
                                case 'approve':
                                    $state.go('dashboard.user.jobs.details.status.workflow.accept-approval', { resource: notification.getLink('resource').encode() });
                                    break;
                                case 'decline':
                                    $state.go('dashboard.user.jobs.details.status.workflow.reject-approval', { resource: notification.getLink('resource').encode() });
                                    break;
                                default:
                                    $state.go('dashboard.user.jobs.details.status', { resource: notification.getLink('resource').encode() });
                                    break;
                            }
                            break;
                        case 'job_operation_cancelled':
                        case 'job_requisite_answers_approved':
                        case 'job_requisite_answer_rejected':
                        case 'job_values_rejected':
                        case 'job_waive_management_fee':
                        case 'job_uphold_management_fee':
                            $state.go('dashboard.user.jobs.details.status', { resource: notification.getLink('resource').encode() });
                            break;
                        case 'quote_accepted':
                            switch ($state.params.link) {
                                case 'acknowledge':
                                    $state.go('dashboard.user.jobs.details.status.workflow.accept-assignment', { resource: $state.params.jobResource });
                                    break;
                                case 'request-proposal':
                                    $state.go('dashboard.user.jobs.details.status.workflow.request-proposal', { resource: $state.params.jobResource });
                                    break;
                                case 'reject':
                                    $state.go('dashboard.user.jobs.details.status.workflow.reject-assignment', { resource: $state.params.jobResource });
                                    break;
                                case 'status':
                                    $state.go('dashboard.user.quote-requests.list.details.quote.workflow', {quoteResource: notification.getLink('resource').encode()});
                                    break;
                                default:
                                    $state.go('dashboard.user.jobs.details.status', { resource: $state.params.jobResource });
                                    break;
                            }
                            break;
                        case 'quote_created':
                            switch ($state.params.link) {
                                case 'decline':
                                    $state.go('dashboard.user.quote-requests.list.details.quote.workflow.abstain', {quoteResource: notification.getLink('resource').encode()});
                                    break;
                                default:
                                    $state.go('dashboard.user.quote-requests.list.details.quote.workflow', {quoteResource: notification.getLink('resource').encode()});
                                    break;
                            }
                            break;
                        case 'quote_submit':
                        case 'quote_values_accepted':
                            switch ($state.params.link) {
                                case 'accept':
                                    $state.go('dashboard.user.quote-requests.list.details.quote.workflow.accept', {quoteResource: notification.getLink('resource').encode()});
                                    break;
                                case 'reject':
                                    $state.go('dashboard.user.quote-requests.list.details.quote.workflow.reject', {quoteResource: notification.getLink('resource').encode()});
                                    break;
                                case 'hold':
                                    $state.go('dashboard.user.quote-requests.list.details.quote.workflow.hold', {quoteResource: notification.getLink('resource').encode()});
                                    break;
                                default:
                                    $state.go('dashboard.user.quote-requests.list.details.quote.workflow', {quoteResource: notification.getLink('resource').encode()});
                                    break;
                            }
                            break;
                        case 'quote_reopened':
                        case 'quote_abstained':
                        case 'quote_cancelled':
                        case 'quote_extension_accepted':
                        case 'quote_extension_rejected':
                        case 'quote_chased':
                        case 'quote_rejected':
                        case 'quote_values_rejected':
                        case 'quote_escalation_added':
                        case 'quote_escalation_removed':
                            $state.go('dashboard.user.quote-requests.list.details.quote.workflow', {quoteResource: notification.getLink('resource').encode()});
                            break;
                        case 'quote_request_more_info':
                            switch ($state.params.link) {
                                case 'decline':
                                    $state.go('dashboard.user.quote-requests.list.details.quote.workflow.abstain', { quoteResource: notification.getLink('resource').encode()});
                                    break;
                                default:
                                    $state.go('dashboard.user.quote-requests.list.details.quote.workflow', { quoteResource: notification.getLink('resource').encode()});
                                    break;
                            }
                            break;
                        case 'quote_extension_requested':
                            switch ($state.params.link) {
                                case 'approve':
                                    $state.go('dashboard.user.quote-requests.list.details.quote.workflow.accept-extension', { quoteResource: notification.getLink('resource').encode() });
                                    break;
                                case 'decline':
                                    $state.go('dashboard.user.quote-requests.list.details.quote.workflow.reject-extension', { quoteResource: notification.getLink('resource').encode() });
                                    break;
                                default:
                                    $state.go('dashboard.user.quote-requests.list.details.quote.workflow', { quoteResource: notification.getLink('resource').encode()});
                                    break;
                            }
                            break;
                        case 'quote_value_approval_request':
                            switch ($state.params.link) {
                                case 'accept':
                                    $state.go('dashboard.user.quote-requests.list.details.quote.workflow.accept-values', {quoteResource: notification.getLink('resource').encode()});
                                    break;
                                case 'reject':
                                    $state.go('dashboard.user.quote-requests.list.details.quote.workflow.reject-values', {quoteResource: notification.getLink('resource').encode()});
                                    break;
                                case 'hold':
                                    $state.go('dashboard.user.quote-requests.list.details.quote.workflow.hold', {quoteResource: notification.getLink('resource').encode()});
                                    break;
                                default:
                                    $state.go('dashboard.user.quote-requests.list.details.quote.workflow', {quoteResource: notification.getLink('resource').encode()});
                                    break;
                            }

                            break;
                        case 'task_approved':
                            $state.go('dashboard.user.tasks.planner-view', { status: 'active', approved: 'true' });
                            break;
                        case 'task_event_approved':
                            $state.go('dashboard.user.tasks.planner-view');
                            break;
                        case 'task_rejected':
                        case 'task_assigned':
                        case 'task_event_rejected':
                            $state.go('dashboard.user.tasks.generate-planner.my-planner');
                            break;
                        case 'task_approval_requested':
                            $state.go('dashboard.user.tasks.generate-planner.awaiting-approval');
                            break;
                        case 'export_finished':
                        case 'report_generation_finished':
                            $state.go('dashboard.user.files.list.details', {
                                fileResource: notification.getLink('resource').encode(),
                                fileId: notification.getLink('resource').replace('/files/', '')
                            });
                            break;
                        case 'ppm_pdf_generation_finished':
                            $state.go('dashboard.user.files.list.details', {
                                fileResource: notification.getLink('resource').encode(),
                                fileId: notification.getLink('resource').replace('/files/', '')
                            });
                            break;
                        case 'import_finished':
                            $state.go('dashboard.admin.imports.list.details', {importResource: notification.getLink('resource').encode()});
                            break;
                        case 'import_waste_entry_finished':
                            if (userManager.hasPermission('user_permission_manage_imports')) {
                                $state.go('dashboard.admin.imports.list.details', {importResource: notification.getLink('resource').encode()});
                            } else {
                                $state.go('dashboard.user.waste-management.imports.list.details', {importResource: notification.getLink('resource').encode()});
                            }
                            break;
                        case 'import_user_asset_finished':
                            $state.go('dashboard.user.assets.manage-assets.imports.list.details', {importResource: notification.getLink('resource').encode()});
                            break;
                        case 'import_user_action_finished':
                            $state.go('dashboard.user.actions.manage-actions.imports.list.details', {importResource: notification.getLink('resource').encode()});
                            break;
                        case 'import_user_task_finished':
                            $state.go('dashboard.user.tasks.manage-tasks.imports.list.details', {importResource: notification.getLink('resource').encode()});
                            break;
                        case 'import_user_asset_task_finished':
                        case 'import_task_frequencies_finished':
                            $state.go('dashboard.user.tasks.generate-planner.imports.list.details', {importResource: notification.getLink('resource').encode()});
                            break;
                        case 'login':
                            $state.go('login');
                            break;
                        case 'action_completed':
                        case 'action_created':
                        case 'action_reassigned':
                        case 'action_cancelled':
                            $state.go('dashboard.user.sites.list.details.actions.manage-actions.list.details.workflow', { actionResource: notification.getLink('resource').encode() });
                            break;
                        case 'surveyrequest_created':
                        case 'surveyrequest_submit':
                        case 'surveyrequest_accepted':
                        case 'surveyrequest_opened':
                            $state.go('dashboard.user.surveys.list.details', { surveyResource: notification.getLink('resource').encode() });
                            break;
                        case 'bulkaction_finished':
                            $state.go('dashboard.user.bulk-actions.list.details', { bulkActionResource: notification.getLink('resource').encode() });
                            break;
                        case 'bulkactioncancelled_finished':
                            $state.go('dashboard.user.jobs.list', {jobStatus: ['cancelled'], bulkAction: notification.params.bulkAction});
                            break;
                        case 'bulkactionoperative_finished':
                        case 'bulkactionreassigned_finished':
                            $state.go('dashboard.user.jobs.list');
                            break;
                        case 'bulkactiontaskcancelled_finished':
                            $state.go('dashboard.user.tasks.generate-planner.my-planner', { status: "inactive" });
                            break;
                        case 'job_operative_reassigned':
                        case 'job_operation_acknowledged':
                        case 'job_created':
                        case 'job_edited':
                        case 'job_extension_accepted':
                        case 'job_extension_rejected':
                        case 'job_proposal_accepted':
                        case 'job_proposal_rejected':
                        case 'job_operation_reopened_from_completed':
                        case 'job_operation_chased':
                        case 'job_paperwork_rejected':
                        case 'job_approval_accepted':
                            $state.go('dashboard.user.jobs.details.status', { resource: notification.getLink('resource').encode() });
                            break;
                        case 'audit_completed':
                            $state.go('dashboard.user.audits.list.details.info', { auditResource: notification.getLink('resource').encode() });
                            break;
                        case 'contract_operation_approval_chased':
                        case 'contract_operation_renewal_approval_chased':
                        case 'contract_operation_expire_chased':
                        case 'contract_review_reminder':
                        case 'contract_expiry_reminder':
                        case 'contract_approval_request':
                        case 'contract_renewal_approval_request':
                        case 'contract_approval_approved':
                        case 'contract_renewal_approval_approved':
                        case 'contract_approval_rejected':
                        case 'contract_renewal_approval_rejected':
                        case 'contract_submitted':
                           switch ($state.params.link) {
                                case 'accept':
                                    $state.go('dashboard.user.contracts.list.details.workflow.approve', { contractResource: notification.getLink('resource').encode() });
                                    break;
                                case 'reject':
                                    $state.go('dashboard.user.contracts.list.details.workflow.reject', { contractResource: notification.getLink('resource').encode() });
                                    break;
                                default:
                                    $state.go('dashboard.user.contracts.list.details.workflow', { contractResource: notification.getLink('resource').encode()});
                                    break;
                            }
                            break;
                        case 'spillage_created':
                        case 'spillage_reassigned':
                        case 'spillage_approval_requested':
                        case 'spillage_cancelled':
                        case 'spillage_started':
                        case 'spillage_accepted':
                        case 'spillage_completed':
                        case 'spillage_chased':
                        case 'spillage_escalation_chased':
                            $state.go('dashboard.user.spillages.list.details.status', {spillageResource: notification.getLink('resource').encode() });
                            break;

                        case 'file_expiry':
                            var expiryDateModifier = notification.metadata.modifier || 14; // system default
                            $state.go('dashboard.user.files.manage-files.all-files-list', {
                                fileExpiresDateStart: moment($state.params.expiry).toISOString(),
                                fileExpiresDateEnd: moment($state.params.expiry).add(expiryDateModifier, 'days').toISOString()
                            });
                            break;
                        case 'file_expiry_today':
                            $state.go('dashboard.user.files.manage-files.all-files-list', {
                                fileExpiresDateStart: moment($state.params.expiry).startOf('day').toISOString(),
                                fileExpiresDateEnd: moment($state.params.expiry).endOf('day').toISOString()
                            });
                            break;
                        case 'absence_entry_requested':
                        case 'absence_entry_approved':
                        case 'absence_entry_rejected':
                            $state.go('dashboard.user.absence-planners.list.details.absence-entry', { resource: notification.getLink('resource').encode() });
                            break;
                        case 'jobs_value_chased':
                            $state.go('dashboard.user.jobs.list', {
                                listJobValue:'true',
                                jobValues: '0',
                                jobsType: 'reactive',
                                jobStatus: ['completed']
                            });
                            break;
                        case 'timesheet_week_submitted':
                                $state.go('dashboard.user.timesheets.list.details', { timesheetsResource: notification.getLink('resource').encode() });
                                break;
                        case 'timesheet_week_approved':
                            $state.go('dashboard.user.timesheets.list.details', { timesheetsResource: notification.getLink('resource').encode() });
                            break;
                        case 'timesheet_week_rejected':
                            $state.go('dashboard.user.timesheets.list.details', { timesheetsResource: notification.getLink('resource').encode() });
                            break;
                        default:
                            $state.go('dashboard.user.dashboard').then(function() {
                                messenger.error('NOTIFICATION_TYPE_NOT_FOUND');
                            });
                    }
                }
            });
        });
    }
});
