(function() {
    'use strict';

    angular
        .module('elogbooks.admin.service-providers')
        .config(registerRoutes)
        .config(registerRoutesList)
        .config(registerRoutesAdd)
        .config(registerRoutesDetails)
        .config(registerRoutesEdit)
        .config(registerRoutesContacts)
        .config(registerRoutesOperatives)
        .config(registerRoutesPriorities)
        .config(registerRoutesPartnerships)
        .config(registerRoutesPrimaryOperatives)
        .config(registerRoutesKPISurveys)
        .config(registerRoutesServiceTypes);

    function registerRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.admin.service-providers', {
                abstract: true,
                templateUrl: 'shared-templates/view.html',
                ncyBreadcrumb: {
                    skip: true
                },
                data: {
                    permissions: ['user_permission_view_service_providers']
                }
            });
    }

    function registerRoutesList($stateProvider) {
        $stateProvider
            .state('dashboard.admin.service-providers.list', {
                url: '/service-providers?page&limit&order&id&{name:any}&active&contractorApproved&reference',
                parent: 'dashboard.admin.service-providers',
                data: {
                    permissions: ['user_permission_view_service_providers']
                },
                views: {
                    '@dashboard.admin.service-providers': {
                        templateUrl: '/modules/admin/service-providers/service-providers-list.html',
                        controller: 'ServiceProvidersListController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    serviceProviderCollection: function($stateParams, apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('serviceproviders'), $stateParams).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                params: {
                    page: '1',
                    limit: '30',
                    order: 'id',
                    id: null,
                    name: null,
                    status: null,
                    contractorApproved: null,
                    reference: null
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SERVICE_PROVIDERS" | translate }}'
                }
            });
    }

    function registerRoutesAdd($stateProvider) {
        $stateProvider
            .state('dashboard.admin.service-providers.list.add', {
                url: '/add',
                parent: 'dashboard.admin.service-providers.list',
                data: {
                    permissions: ['user_permission_manage_service_providers']
                },
                views: {
                    '@dashboard.admin.service-providers': {
                        templateUrl: '/modules/admin/service-providers/add-edit/service-providers-form.html',
                        controller: 'ServiceProvidersAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    serviceProviderResponse: function() {
                        return {};
                    },
                    serviceProviderGroupsResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('serviceprovidergroups'));
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            });
    }

    function registerRoutesDetails($stateProvider) {
        $stateProvider
            .state('dashboard.admin.service-providers.list.details', {
                url: '/details/{resource}',
                abstract: true,
                parent: 'dashboard.admin.service-providers.list',
                data: {
                    permissions: ['user_permission_view_service_providers']
                },
                views: {
                    '@dashboard.admin.service-providers': {
                        templateUrl: '/modules/admin/service-providers/details/service-provider-details.html',
                        controller: 'ServiceProviderDetailsController',
                        controllerAs: 'vm',
                    }
                },
                resolve: {
                    serviceProviderResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.resource.decode()).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().serviceProviderResponse.name }}'
                }
            })
            .state('dashboard.admin.service-providers.list.details.info', {
                url: '/info',
                parent: 'dashboard.admin.service-providers.list.details',
                data: {
                    permissions: ['user_permission_view_service_providers']
                },
                views: {
                    '@dashboard.admin.service-providers.list.details': {
                        templateUrl: '/modules/admin/service-providers/details/info/service-provider-info.html',
                        controller: 'ServiceProviderInfoController',
                        controllerAs: 'vm',
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }
            });
    }

    function registerRoutesEdit($stateProvider) {
        $stateProvider
            .state('dashboard.admin.service-providers.list.details.info.edit', {
                url: '/edit',
                parent: 'dashboard.admin.service-providers.list.details.info',
                data: {
                    permissions: ['user_permission_manage_service_providers']
                },
                views: {
                    '@dashboard.admin.service-providers.list.details': {
                        templateUrl: '/modules/admin/service-providers/add-edit/service-providers-form.html',
                        controller: 'ServiceProvidersAddEditController',
                        controllerAs: 'vm',
                    }
                },
                resolve: {
                    serviceProviderCollection: function() {
                        return {};
                    },
                    serviceProviderGroupsResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('serviceprovidergroups'));
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

    function registerRoutesContacts($stateProvider) {
        $stateProvider
            .state('dashboard.admin.service-providers.list.details.contacts', {
                url: '/contacts',
                parent: 'dashboard.admin.service-providers.list.details',
                data: {
                    permissions: ['user_permission_view_service_providers']
                },
                views: {
                    '@dashboard.admin.service-providers.list.details': {
                        templateUrl: '/modules/admin/service-providers/details/contacts/contacts-list.html',
                        controller: 'ServiceProviderContactsListController',
                        controllerAs: 'vm',
                    }
                },
                resolve: {
                    contactInformationResponse: function(serviceProviderResponse) {
                        return serviceProviderResponse.getResource('contactinformation');
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"CONTACTS" | translate }}'
                }
            });
    }

    function registerRoutesOperatives($stateProvider) {
        $stateProvider
            .state('dashboard.admin.service-providers.list.details.operatives', {
                url: '/operatives?operativePage&operativeLimit&operativeOrder&operativeId&operativeName',
                parent: 'dashboard.admin.service-providers.list.details',
                data: {
                    permissions: ['user_permission_view_service_providers']
                },
                views: {
                    '@dashboard.admin.service-providers.list.details': {
                        templateUrl: '/modules/admin/service-providers/details/operatives/operatives-list.html',
                        controller: 'ServiceProviderOperativesListController',
                        controllerAs: 'vm',
                    }
                },
                resolve: {
                    operativesCollection: function(apiClient, serviceProviderResponse, $stateParams) {
                        return apiClient.get(serviceProviderResponse.getLink('operatives'), angular.extend({}, $stateParams, {
                            page: $stateParams.operativePage,
                            limit: $stateParams.operativeLimit,
                            order: $stateParams.operativeOrder,
                            id: $stateParams.operativeId,
                            name: $stateParams.operativeName,
                            includeAllStatuses: true
                        })).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                params: {
                    operativePage: '1',
                    operativeLimit: '30',
                    operativeOrder: 'name',
                    operativeId: null,
                    operativeName: null,
                },
                ncyBreadcrumb: {
                    label: '{{ ::"OPERATIVES" | translate }}'
                }
            })
            .state('dashboard.admin.service-providers.list.details.operatives.add', {
                url: '/add',
                parent: 'dashboard.admin.service-providers.list.details.operatives',
                data: {
                    permissions: ['user_permission_manage_service_providers']
                },
                views: {
                    '@dashboard.admin.service-providers.list.details': {
                        templateUrl: '/modules/admin/service-providers/details/operatives/add-edit/operatives-form.html',
                        controller: 'ServiceProviderOperativesAddEditController',
                        controllerAs: 'vm',
                    }
                },
                resolve: {
                    operativeResponse: function() {
                        return {};
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state('dashboard.admin.service-providers.list.details.operatives.details', {
                url: '/details/{operativeResource}',
                parent: 'dashboard.admin.service-providers.list.details.operatives',
                data: {
                    permissions: ['user_permission_view_service_providers']
                },
                views: {
                    '@dashboard.admin.service-providers.list.details': {
                        templateUrl: '/modules/admin/service-providers/details/operatives/operatives-detail.html',
                        controller: 'ServiceProviderOperativesDetailsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    operativeResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.operativeResource.decode());
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().operativeResponse._links.user.title }}'
                }
            });
    }

    function registerRoutesPriorities($stateProvider) {
        $stateProvider
            .state('dashboard.admin.service-providers.list.details.priorities', {
                url: '/priorities?priorityPage&priorityLimit&priorityOrder&priorityName&priorityStatus',
                parent: 'dashboard.admin.service-providers.list.details',
                data: {
                    permissions: ['user_permission_view_service_providers']
                },
                views: {
                    '@dashboard.admin.service-providers.list.details': {
                        templateUrl: '/modules/common/priorities/list/priorities-list.html',
                        controller: 'PrioritiesListController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    prioritiesCollection: function(apiClient, serviceProviderResponse, $stateParams) {
                        return apiClient.get(serviceProviderResponse.getLink('priorities'), angular.extend({}, $stateParams, {
                            page: $stateParams.priorityPage,
                            limit: $stateParams.priorityLimit,
                            order: $stateParams.priorityOrder,
                            name: $stateParams.priorityName,
                            active: $stateParams.priorityStatus
                        }));
                    },
                    priorityCreateType: function() {
                        return 'create-service-provider-priority';
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"PRIORITIES" | translate }}'
                },
                params: {
                    priorityPage: '1',
                    priorityLimit: '30',
                    priorityOrder: 'name',
                    priorityName: null,
                    priorityStatus: null
                }
            })
            .state('dashboard.admin.service-providers.list.details.priorities.add', {
                url: '/add',
                parent: 'dashboard.admin.service-providers.list.details.priorities',
                data: {
                    permissions: ['user_permission_manage_service_providers']
                },
                views: {
                    '@dashboard.admin.service-providers.list.details': {
                        templateUrl: '/modules/common/priorities/addedit/priorities-addedit.html',
                        controller: 'PrioritiesAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    priorityResponse: function() {
                        return {};
                    },
                    priorityCreateLink: function(prioritiesCollection) {
                        return prioritiesCollection.getLink('create-service-provider-priority');
                    },
                    config: function() {
                        return {
                            isIncludeDashboardLimitReached: 'disabled',
                            noBorder: false
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state('dashboard.admin.service-providers.list.details.priorities.details', {
                url: '/details/{priorityResource}',
                abstract: true,
                parent: 'dashboard.admin.service-providers.list.details.priorities',
                data: {
                    permissions: ['user_permission_view_service_providers']
                },
                resolve: {
                    priorityResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.priorityResource.decode());
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().priorityResponse.name }}'
                }
            })
            .state('dashboard.admin.service-providers.list.details.priorities.details.priorityinfo', {
                url: '/info',
                parent: 'dashboard.admin.service-providers.list.details.priorities.details',
                data: {
                    permissions: ['user_permission_view_service_providers']
                },
                views: {
                    '@dashboard.admin.service-providers.list.details': {
                        templateUrl: '/modules/common/priorities/details/info/priorities-info.html',
                        controller: 'PrioritiesDetailsInfoController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    config: function () {
                        return {
                            isIncludeDashboardLimitReached: 'disabled',
                            noBorder: false
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }
            })
            .state('dashboard.admin.service-providers.list.details.priorities.details.priorityinfo.edit', {
                url: '/edit',
                parent: 'dashboard.admin.service-providers.list.details.priorities.details.priorityinfo',
                data: {
                    permissions: ['user_permission_manage_service_providers']
                },
                views: {
                    '@dashboard.admin.service-providers.list.details': {
                        templateUrl: '/modules/common/priorities/addedit/priorities-addedit.html',
                        controller: 'PrioritiesAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    priorityCreateLink: function() {
                        return null;
                    },
                    config: function() {
                        return {
                            isIncludeDashboardLimitReached: 'disabled',
                            noBorder: false
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

    function registerRoutesPartnerships($stateProvider) {
        $stateProvider
            .state('dashboard.admin.service-providers.list.details.partnerships', {
                url: '/partnerships?partnershipPage&partnershipLimit&partnershipOrder&partnershipActive&siteStatus&includeInactiveServiceProviders&includeTrainingServiceProviders&site',
                parent: 'dashboard.admin.service-providers.list.details',
                data: {
                    permissions: ['user_permission_view_service_providers']
                },
                views: {
                    '@dashboard.admin.service-providers.list.details': {
                        templateUrl: '/modules/admin/service-providers/details/partnerships/partnerships-list.html',
                        controller: 'ServiceProviderPartnershipsListController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    partnershipsResponse: function(apiClient, serviceProviderResponse, $stateParams) {
                        return apiClient.get(
                            serviceProviderResponse.getLink('partnerships'),
                            angular.extend({}, $stateParams, {
                                page: $stateParams.partnershipPage,
                                limit: $stateParams.partnershipLimit,
                                order: $stateParams.partnershipOrder,
                                active: $stateParams.partnershipActive,
                                site: $stateParams.site,
                                siteStatus: $stateParams.siteStatus
                            })
                        );
                    }
                },
                params: {
                    partnershipPage: '1',
                    partnershipLimit: '30',
                    partnershipOrder: 'site',
                    partnershipActive: null,
                    siteStatus: null,
                    includeInactiveServiceProviders: 'true',
                    includeTrainingServiceProviders: 'true',
                    site: null
                },
                ncyBreadcrumb: {
                    label: '{{ ::"PARTNERSHIPS" | translate }}'
                }
            })
            .state('dashboard.admin.service-providers.list.details.partnerships.add', {
                url: '/add',
                parent: 'dashboard.admin.service-providers.list.details.partnerships',
                data: {
                    permissions: ['user_permission_manage_service_providers']
                },
                views: {
                    '@dashboard.admin.service-providers.list.details': {
                        templateUrl: '/modules/admin/service-providers/details/partnerships/add/partnerships-form.html',
                        controller: 'ServiceProviderPartnershipsAddController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    siteCollection: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('sites')).then(function(response) {
                            return response;
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state('dashboard.admin.service-providers.list.details.partnerships.details', {
                url: '/{partnershipResource}',
                parent: 'dashboard.admin.service-providers.list.details.partnerships',
                data: {
                    permissions: ['user_permission_view_service_providers']
                },
                abstract: true,
                resolve: {
                    partnershipResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.partnershipResource.decode());
                    },
                    priorityCreateType: function() {
                        return 'create-partnership-priority';
                    }
                },
                views: {
                    '@dashboard.admin.service-providers.list.details': {
                        templateUrl: '/modules/common/partnership/tabs.html',
                        controller: 'PartnershipTabsController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().partnershipResponse._links.site.title }}'
                }
            })
            .state('dashboard.admin.service-providers.list.details.partnerships.details.info', {
                url: '/info',
                parent: 'dashboard.admin.service-providers.list.details.partnerships.details',
                data: {
                    permissions: ['user_permission_view_service_providers']
                },
                views: {
                    'tab-panel@dashboard.admin.service-providers.list.details.partnerships.details': {
                        templateUrl: '/modules/common/partnership/info/partnership-info.html',
                        controller: 'PartnershipInfoController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }
            })
            .state('dashboard.admin.service-providers.list.details.partnerships.details.info.edit', {
                url: '/edit',
                parent: 'dashboard.admin.service-providers.list.details.partnerships.details.info',
                data: {
                    permissions: ['user_permission_manage_service_providers']
                },
                views: {
                    '@dashboard.admin.service-providers.list.details': {
                        templateUrl: '/modules/common/partnership/edit/partnership-edit.html',
                        controller: 'PartnershipEditController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            })
            .state('dashboard.admin.service-providers.list.details.partnerships.details.priorities', {
                url: '/priorities?priorityPage&priorityLimit&priorityOrder&priorityName&priorityStatus',
                parent: 'dashboard.admin.service-providers.list.details.partnerships.details',
                data: {
                    permissions: ['user_permission_view_service_providers']
                },
                views: {
                    'tab-panel@dashboard.admin.service-providers.list.details.partnerships.details': {
                        templateUrl: '/modules/common/priorities/list/priorities-list.html',
                        controller: 'PrioritiesListController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    prioritiesCollection: function(apiClient, partnershipResponse, $stateParams) {
                        return apiClient.get(partnershipResponse.getLink('priorities'), angular.extend({}, $stateParams, {
                            page: $stateParams.priorityPage,
                            limit: $stateParams.priorityLimit,
                            order: $stateParams.priorityOrder,
                            name: $stateParams.priorityName,
                            active: $stateParams.priorityStatus
                        }));
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"PRIORITIES" | translate }}'
                },
                params: {
                    priorityPage: '1',
                    priorityLimit: '30',
                    priorityOrder: 'name',
                    priorityName: null,
                    priorityStatus: null
                }
            })
            .state('dashboard.admin.service-providers.list.details.partnerships.details.priorities.add', {
                url: '/add',
                parent: 'dashboard.admin.service-providers.list.details.partnerships.details.priorities',
                data: {
                    permissions: ['user_permission_manage_service_providers']
                },
                views: {
                    'tab-panel@dashboard.admin.service-providers.list.details.partnerships.details': {
                        templateUrl: '/modules/common/priorities/addedit/priorities-addedit.html',
                        controller: 'PrioritiesAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    priorityResponse: function() {
                        return {};
                    },
                    priorityCreateLink: function(prioritiesCollection) {
                        return prioritiesCollection.getLink('create-partnership-priority');
                    },
                    config: function() {
                        return {
                            isIncludeDashboardLimitReached: 'disabled',
                            noBorder: true
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state('dashboard.admin.service-providers.list.details.partnerships.details.priorities.details', {
                url: '/details/{priorityResource}',
                abstract: true,
                parent: 'dashboard.admin.service-providers.list.details.partnerships.details.priorities',
                data: {
                    permissions: ['user_permission_view_service_providers']
                },
                resolve: {
                    priorityResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.priorityResource.decode());
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().priorityResponse.name }}'
                }
            })
            .state('dashboard.admin.service-providers.list.details.partnerships.details.priorities.details.priorityinfo', {
                url: '/info',
                parent: 'dashboard.admin.service-providers.list.details.partnerships.details.priorities.details',
                data: {
                    permissions: ['user_permission_view_service_providers']
                },
                views: {
                    'tab-panel@dashboard.admin.service-providers.list.details.partnerships.details': {
                        templateUrl: '/modules/common/priorities/details/info/priorities-info.html',
                        controller: 'PrioritiesDetailsInfoController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    config: function () {
                        return {
                            isIncludeDashboardLimitReached: 'disabled',
                            noBorder: true
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }
            })
            .state('dashboard.admin.service-providers.list.details.partnerships.details.priorities.details.priorityinfo.edit', {
                url: '/edit',
                parent: 'dashboard.admin.service-providers.list.details.partnerships.details.priorities.details.priorityinfo',
                data: {
                    permissions: ['user_permission_manage_service_providers']
                },
                views: {
                    'tab-panel@dashboard.admin.service-providers.list.details.partnerships.details': {
                        templateUrl: '/modules/common/priorities/addedit/priorities-addedit.html',
                        controller: 'PrioritiesAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    priorityCreateLink: function() {
                        return null;
                    },
                    config: function() {
                        return {
                            isIncludeDashboardLimitReached: 'disabled',
                            noBorder: true,
                            goToStateAfterDelete: 'dashboard.admin.service-providers.list.details.partnerships.details.priorities'
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            })
            .state('dashboard.admin.service-providers.list.details.partnerships.details.surveys', {
                url: '/surveys',
                parent: 'dashboard.admin.service-providers.list.details.partnerships.details',
                data: {
                    permissions: ['user_permission_view_service_providers']
                },
                resolve: {
                    surveysResponse: function(apiClient, partnershipResponse) {
                        return apiClient.get(partnershipResponse.getLink('surveys')).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                views: {
                    'tab-panel@dashboard.admin.service-providers.list.details.partnerships.details': {
                        templateUrl: '/modules/common/partnership/surveys/list/partnership-surveys.html',
                        controller: 'PartnershipSurveysController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SURVEYS" | translate }}'
                }
            })
            .state('dashboard.admin.service-providers.list.details.partnerships.details.surveys.add', {
                url: '/add',
                parent: 'dashboard.admin.service-providers.list.details.partnerships.details.surveys',
                data: {
                    permissions: ['user_permission_manage_service_providers']
                },
                views: {
                    'tab-panel@dashboard.admin.service-providers.list.details.partnerships.details': {
                        templateUrl: '/modules/common/partnership/surveys/add/survey-add.html',
                        controller: 'PartnershipSurveyAddController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    surveysRootResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('surveys'), {limit: 200, status: 'active'}).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SURVEY_ADD" | translate }}'
                }
            })
            .state('dashboard.admin.service-providers.list.details.partnerships.details.service-types', {
                url: '/service-types',
                parent: 'dashboard.admin.service-providers.list.details.partnerships.details',
                data: {
                    permissions: ['user_permission_view_service_providers']
                },
                views: {
                    'tab-panel@dashboard.admin.service-providers.list.details.partnerships.details': {
                        templateUrl: '/modules/common/service-types/list/service-types.html',
                        controller: 'CommonServiceTypesController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    serviceTypesCollectionResponse: function(apiClient, partnershipResponse) {
                        return apiClient.get(partnershipResponse.getLink('servicetypes')).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function() {
                        return {
                            noBorder: true
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SERVICE_TYPES" | translate }}'
                }
            })
            .state('dashboard.admin.service-providers.list.details.partnerships.details.service-types.add', {
                url: '/add',
                parent: 'dashboard.admin.service-providers.list.details.partnerships.details.service-types',
                data: {
                    permissions: ['user_permission_manage_service_providers']
                },
                views: {
                    'tab-panel@dashboard.admin.service-providers.list.details.partnerships.details': {
                        templateUrl: '/modules/common/service-types/add/service-type-add.html',
                        controller: 'CommonServiceTypeAddController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    serviceTypesRootResponse: function($stateParams, apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('servicetypes'), $stateParams).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SERVICE_TYPE_ADD" | translate }}'
                }
            });
    }

    function registerRoutesPrimaryOperatives($stateProvider) {
        $stateProvider
            .state('dashboard.admin.service-providers.list.details.primary-operatives', {
                url: '/primary-operatives',
                parent: 'dashboard.admin.service-providers.list.details',
                data: {
                    permissions: ['user_permission_view_service_providers']
                },
                views: {
                    '@dashboard.admin.service-providers.list.details': {
                        templateUrl: '/modules/common/primary-operatives/primary-operatives-list.html',
                        controller: 'CommonServiceProvidersDetailsPrimaryOperativesController',
                        controllerAs: 'vm',
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"PRIMARY_OPERATIVES" | translate }}'
                }
            })
            .state('dashboard.admin.service-providers.list.details.primary-operatives.edit', {
                url: '/edit',
                parent: 'dashboard.admin.service-providers.list.details.primary-operatives',
                data: {
                    permissions: ['user_permission_manage_service_providers']
                },
                views: {
                    '@dashboard.admin.service-providers.list.details': {
                        templateUrl: '/modules/admin/service-providers/details/primary-operatives/primary-operatives-addedit.html',
                        controller: 'ServiceProviderDetailsPrimaryOperativesAddEditController',
                        controllerAs: 'vm',
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

    function registerRoutesKPISurveys($stateProvider) {
        $stateProvider
            .state('dashboard.admin.service-providers.list.details.kpi-surveys', {
                url: '/kpi-surveys',
                parent: 'dashboard.admin.service-providers.list.details',
                data: {
                    permissions: ['user_permission_manage_service_providers'] // BE has manage SP permission in cgetAction endpoint.
                },
                views: {
                    '@dashboard.admin.service-providers.list.details': {
                        templateUrl: '/modules/common/kpi-surveys/list/kpi-surveys.html',
                        controller: 'KPISurveyListController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    surveysResponse: function(apiClient, serviceProviderResponse) {
                        return apiClient.get(serviceProviderResponse.getLink('surveys')).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ :: "SURVEY" | translate }}'
                }
            })
            .state('dashboard.admin.service-providers.list.details.kpi-surveys.add', {
                url: '/add',
                parent: 'dashboard.admin.service-providers.list.details.kpi-surveys',
                data: {
                    permissions: ['user_permission_manage_service_providers']
                },
                views: {
                    '@dashboard.admin.service-providers.list.details': {
                        templateUrl: '/modules/common/kpi-surveys/add/kpi-surveys.add.html',
                        controller: 'KPISurveyAddController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    surveysRootResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('surveys'), {limit: 200, status: 'active'}).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            });
    };

    function registerRoutesServiceTypes($stateProvider) {
        $stateProvider
            .state('dashboard.admin.service-providers.list.details.service-types', {
                url: '/service-types',
                parent: 'dashboard.admin.service-providers.list.details',
                data: {
                    permissions: ['user_permission_view_service_providers']
                },
                views: {
                    '@dashboard.admin.service-providers.list.details': {
                        templateUrl: '/modules/common/service-types/list/service-types.html',
                        controller: 'CommonServiceTypesController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    serviceTypesCollectionResponse: function(apiClient, serviceProviderResponse) {
                        return apiClient.get(serviceProviderResponse.getLink('servicetypes')).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function() {
                        return {
                            noBorder: false
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SERVICE_TYPES" | translate }}'
                }
            })
            .state('dashboard.admin.service-providers.list.details.service-types.add', {
                url: '/add',
                parent: 'dashboard.admin.service-providers.list.details.service-types',
                data: {
                    permissions: ['user_permission_manage_service_providers']
                },
                views: {
                    '@dashboard.admin.service-providers.list.details': {
                        templateUrl: '/modules/common/service-types/add/service-type-add.html',
                        controller: 'CommonServiceTypeAddController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    serviceTypesRootResponse: function($stateParams, apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('servicetypes'), $stateParams).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SERVICE_TYPE_ADD" | translate }}'
                }
            });
    };
})();
